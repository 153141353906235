import DOMPurify from "dompurify";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { ACTIONS } from "../../constants";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Modal from "../components/Modal";
import RichText from "../components/RichText";
import { useAuth, useClauseQuery, useContractQuery } from "../hooks";

const EditClauseModal = ({ handleClose, clause, endorsementId, contractData }) => {
  const {
    contractId,
    deleteContractClause,
    isDeleting,
    isUpdating,
    productRef,
    updateContractClause,
  } = useClauseQuery();
  const { updateContract } = useContractQuery();
  const [value, setValue] = useState(clause.body);
  const { checkPermissions } = useAuth(contractData);

  const handleSubmit = async () => {
    if (!clause.locked) {
      await updateContractClause({
        clauseId: clause.reference,
        data: { body: value },
        productRef,
        contractId,
        endorsementId,
      });
    }
    handleClose();
  };

  const handleRemove = async () => {
    await Promise.all([
      deleteContractClause({
        // eslint-disable-next-line react/prop-types
        clauseId: clause.reference,
        productRef,
        contractId,
        endorsementId,
      }),
      updateContract({
        productRef,
        contractId,
        // eslint-disable-next-line react/prop-types
        data: { payload: { removedClauses: { [clause.reference]: true } } },
      }),
    ]);
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      headingText={
        checkPermissions(ACTIONS.EDIT_CLAUSE) ? `Edit clause — ${clause.title}` : clause.title
      }
    >
      <div className="p-6 w-full">
        {clause.locked && (
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(clause.body) }}
            className="mb-8"
          />
        )}
        {!clause.locked && (
          <RichText
            value={value}
            onChange={setValue}
            className="mb-8"
            isReadOnly={!checkPermissions(ACTIONS.EDIT_CLAUSE)}
          />
        )}
        <div className="flex justify-between mb-2">
          {checkPermissions(ACTIONS.REMOVE_CLAUSE) && (
            <Button
              onClick={handleRemove}
              kind="danger-secondary"
              className="pr-8 pl-4"
              isDisabled={isDeleting}
            >
              <Icon name="trashcan" className="w-5 mr-4 inline-flex fill-current text-gray-700" />
              Remove
            </Button>
          )}

          <div>
            <Button onClick={handleClose} className="mr-4">
              Cancel
            </Button>
            {checkPermissions(ACTIONS.EDIT_CLAUSE) && !clause.locked && (
              <Button kind="primary" onClick={handleSubmit} isDisabled={isUpdating || isDeleting}>
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditClauseModal;
