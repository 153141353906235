export const UMR = {
  ROPNER: {
    ID: ["B085620L2002"],
    CONSORTIUM_ID: "B1234RO2023RO23",
    CONSORTIUM_COMMISSION: 13,
  },
  GALLAGHER: { // AJG
    ID: ["B1262FSM0017921", "B1262FSM0017922", "B1262FSM0021725"],
    CONSORTIUM_ID: "B1262FSM0021725",
    CONSORTIUM_COMMISSION: 22.5,
  },
  LIG: {
    ID: ["B1586LIG2021LS89", "B1586LIG2022LS89", "B1586LIG2024LS789", "B1586LIG2024LS89", "B1586LIG2025LS789"],
    CONSORTIUM_ID: "B1586LIG2025LS789",
    CONSORTIUM_COMMISSION: 22.5,
  },
  RAP: {
    ID: ["B1368M215170", "B1368M225207"],
    CONSORTIUM_ID: "C-RAP", // TODO that value should be changed before pushing it to production env
    CONSORTIUM_COMMISSION: 13, // TODO that value should be changed before pushing it to production env
  },
  AH: {
    ID: ["B0999DUA12342021"],
    CONSORTIUM_ID: "C-AH", // TODO that value should be changed before pushing it to production env
    CONSORTIUM_COMMISSION: 13, // TODO that value should be changed before pushing it to production env
  },
  PB: {
    ID: ["B0507FRG2100008", "B0507FRG2200008", "B0507FRG2400008", "B0507FGI2500005"],
    CONSORTIUM_ID: "B0507FGI2500005",
    CONSORTIUM_COMMISSION: 22.5,
  },
  SURE: {
    ID: ["B1922WA000160V"],
    CONSORTIUM_ID: "C-SURE", // TODO that value should be changed before pushing it to production env
    CONSORTIUM_COMMISSION: 13, // TODO that value should be changed before pushing it to production env
  },
  AMWINS: {
    ID: ["B1230FN5207A24", "B1230FN04185A25"],
    CONSORTIUM_ID: "B1230FN04185A25",
    CONSORTIUM_COMMISSION: 22.5,
  },
  TYSERS: {
    ID: ["B0572MA24L051", "B0572MA25L075"],
    CONSORTIUM_ID: "B0572MA25L075",
    CONSORTIUM_COMMISSION: 22.5,
  },
};

export default UMR;
